import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as moment from "moment";
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingtable$ = new BehaviorSubject<boolean>(false);
  private _filterSource = new BehaviorSubject<any>([]);
  filter$ = this._filterSource.asObservable();
  private apiUrl = environment.apiUrl;
  private googleauth = environment.googleauth;

  constructor(private http: HttpClient,
    private location: Location) { }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isLoadingtable$() {
    return this._isLoadingtable$.asObservable();
  }

  getFilters() {
    return this._filterSource.getValue();
  }

  setFilters(filter): void {
    this._filterSource.next(filter);
  }

  getall(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/account`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getallActive(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/account`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getActivityUrlInfo(param) {
    return this.http.post(`${this.apiUrl}/activity/log/url/info`, param)
  }

  getChannel(param) {
    return this.http.post(`${this.apiUrl}/general/channel`, { creator: param })
  }

  channerExistOrNot(channel_id) {
    return this.http.post(`${this.apiUrl}/channel/exists/not`, { channel_id })
  }

  getclip(rights_holder = "") {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/clip`, { rights_holder }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }
  getallcategory(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/category`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }


  getallenergyleavel(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/energy/level`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );

  }

  creatorrightholder(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/creator/rights/holder`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );

  }

  getallchannel(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/channel`, params).pipe(

      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getallartist() {
    return this.http.get(`${this.apiUrl}/general/artist`);
  }

  getallcontracts(params) {
    return this.http.post(`${this.apiUrl}/general/contract`, params);
  }

  getactivecontract(params, typev?) {
    return this.http.post(`${this.apiUrl}/general/rights/holders/contract`, params);
  }

  getactiveyoutubecontract(params, typev?) {
    if (typev == 'yes') {
      return this.http.post(`${this.apiUrl}/general/rights/holders/contract`, params);
    } else {
      return this.http.post(`${this.apiUrl}/general/rights/holders/youtube/contract`, params);
    }
  }
  verifychennal(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/channel/info`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  accesstoken(channel_id, json) {
    return this.http.put(`${this.apiUrl}/channel/access-token/${channel_id}`, json);
  }

  getAccessToken(code, redirect_uri) {
    return this.http.post(`${this.apiUrl}/channel/google/access-token`, {
      code,
      redirect_uri
    })
  }

  getGoogleAuth(redirect_uri, state = "") {


    return this.http.post(`${this.apiUrl}/channel/google/code`, { redirect_uri, state });
  }

  getLabelFromDate(startDate, endDate) {
    let label = "";
    if (startDate && endDate) {
      const start = moment(startDate).format("MMM DD,YYYY")
      const end = moment(endDate).format("MMM DD,YYYY")

      if (startDate == moment().subtract(6, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 1 Week - (" + start + " - " + end + ")";
      } else if (startDate == moment().format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Today";
      } else if (startDate == moment().subtract(1, "days").format('YYYY-MM-DD') && endDate == moment().subtract(1, "days").format('YYYY-MM-DD')) {
        label = "Yesterday";
      } else if (startDate == moment().subtract(27, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 28 Days - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(89, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 90 Days - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(364, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 365 Days - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(1, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 1 Month - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(3, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 3 Month - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(6, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 6 Month - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(12, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 12 Month - (" + start + " - " + end + ")";
      } else {
        label = start + " - " + end;
      }
    }

    return label;

  }

  convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? (Math.round((Math.abs(Number(labelValue)) * 100) / 100) / 1.0e+9) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? (Math.round((Math.abs(Number(labelValue)) * 100) / 100) / 1.0e+6) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? (Math.round((Math.abs(Number(labelValue)) * 100) / 100) / 1.0e+3) + "K"

          : Math.abs(Number(labelValue));

  }

}
